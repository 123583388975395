<template>
  <div class="form-card shadow-lg">
    <h4 class="mb-4 font-weight-bold">Reset your password</h4>

    <div class="login input-group my-3">
      <input
        v-if="showPass"
        type="password"
        autocorrect="off"
        autocomplete="off"
        name="new password"
        v-validate="'required'"
        class="form-control"
        placeholder="New Password"
        v-model="Newpassword"
        aria-label="Password"
        aria-describedby="Password"
      />

      <input
        v-else
        type="text"
        autocorrect="off"
        autocomplete="off"
        name="new password"
        v-validate="'required'"
        class="form-control"
        placeholder="New Password"
        v-model="Newpassword"
        aria-label="Password"
        aria-describedby="Password"
      />
      <button
        style="cursor: pointer"
        @click="showPassword"
        class="btn-showPassword float-right mt-1"
      >
        <span v-if="showPass" class="material-icons-outlined text-muted">
          visibility_off
        </span>
        <span v-else class="material-icons-outlined text-muted">
          visibility
        </span>
      </button>
      <span
        class="text-danger text-sm mx-1 mt-0 mb-3"
        v-show="errors.has('new password')"
        >{{ errors.first("new password") }}</span
      >
    </div>

    <div class="login input-group mt-3 mb-2">
      <input
        v-if="showConfirmPass"
        type="password"
        autocorrect="off"
        autocomplete="off"
        name="confirm password"
        v-validate="'required'"
        class="form-control"
        placeholder="Confirm Password"
        v-model="Confirmpassword"
        aria-label="Password"
        aria-describedby="Password"
      />

      <input
        v-else
        type="text"
        autocorrect="off"
        autocomplete="off"
        name="confirm password"
        v-validate="'required'"
        class="form-control"
        placeholder="Confirm Password"
        v-model="Confirmpassword"
        aria-label="Password"
        aria-describedby="Password"
      />

      <button
        style="cursor: pointer"
        @click="showConfirmPassword"
        class="btn-showConfirmPassword float-right mt-1"
      >
        <span v-if="showConfirmPass" class="material-icons-outlined text-muted">
          visibility_off
        </span>
        <span v-else class="material-icons-outlined text-muted">
          visibility
        </span>
      </button>
      <span
        class="text-danger text-sm mx-1 mt-0"
        v-show="errors.has('confirm password')"
        >{{ errors.first("confirm password") }}</span
      >
    </div>

    <!-- <small class="mb-4"
      ><a @click="back" href="" class="text-muted">Back to Login</a>
    </small> -->

    <button @click="reset" class="btn btn-primary btn-signin mt-4">
      Reset Password
    </button>

    <div class="row mt-4">
      <div class="col">
        <small class="mb-4">
          <a @click="back" href="" class="text-muted float-right">
            Back to Login
          </a>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";

export default {
  data() {
    return {
      Newpassword: null,
      Confirmpassword: null,
      token: "",
      showPass: true,
      showConfirmPass: true,
    };
  },

  methods: {
    showPassword() {
      this.showPass = !this.showPass;
    },

    showConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },

    reset() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.Newpassword === this.Confirmpassword) {
            const payload = {
              token: this.token,
              password: this.Newpassword,
              password_confirmation: this.Confirmpassword,
            };

            Auth.updatePassword(payload)
              .then((response) => {
                this.$toast.success(response.data.message);

                localStorage.removeItem("accessToken");
                localStorage.removeItem("userInfo");
                // localStorage.removeItem("me");

                this.$router.push({ name: "SignIn" });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$toast.error(
              "New Password and Confirm Password does not match!"
            );
          }
        } else {
          this.$toast.error("Please fill in all the fields");
        }
      });
    },

    back() {
      this.$router.push({ name: "SignIn" });
    },
  },

  created() {
    this.token = this.$route.query.token;
    // //
    // //
  },
};
</script>

<style lang="css" scoped>
/* .input-group{
  border-radius: 10px;
} */
hr {
  color: #818181;
  width: 100%;
  margin: 0rem;
}
.form-card {
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 350px;
  /* min-height: 300px; */
  padding: 2rem;
  width: 400px;
  margin: 0 auto;
}
small {
  font-size: 14px;
}
small a {
  text-decoration: none;
}
.btn {
  width: 100%;
}
.btn-white {
  border-color: rgb(207, 207, 207);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-white:hover,
.btn-white:active {
  border-color: #e9e9e9;
  background-color: #e9e9e9;
}
.login.input-group {
  display: block;
}
.login.input-group .text-danger {
  margin-top: 0.5rem;
}
.login.input-group span {
  display: block;
}
.login.input-group input {
  border-radius: 6px !important;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .form-card {
    min-width: auto;
    width: auto;
    min-width: 300px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 900px) {
  .form-card {
    width: auto;
    min-width: 300px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 400px) {
  .form-card {
    width: auto;
    min-width: auto;
    margin: 0 auto;
  }
}
.btn-showPassword {
  z-index: 999;
  margin-left: auto;
  margin-right: 0.5rem;
  translate: 0px -35px;
}

.btn-showConfirmPassword {
  z-index: 999;
  margin-left: auto;
  margin-right: 0.5rem;
  translate: 0px -35px;
}
</style>
