<template>
  <div class="login-form-card shadow-lg">
    <b-modal
      id="open-beta-user-agreement"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <div class="row juris-modal">
        <div class="col">
          <h4 class="mt-3"><strong>Beta User Agreement</strong></h4>

          <p class="mt-3 mb-4">
            This Beta User Agreement ("Agreement") is entered into between
            <strong
              >NEXLAW PTY LTD ("NexLaw"), a legal AI company with its registered
              company in NSW, Australia</strong
            >, and the Beta User ("User") accessing and using the beta version
            of NexLaw's AI-powered legal assistant platform available at
            www.nexlaw.ai ("Platform").
          </p>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>1. Beta Testing</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">1.1</div>
                    <div class="col">
                      User acknowledges that the Platform is currently in the
                      beta testing phase and may contain bugs, errors, or other
                      issues. User agrees to provide feedback and report any
                      issues encountered during the beta testing period.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">1.2</div>
                    <div class="col">
                      User understands that the beta version of the Platform is
                      provided on an "as-is" basis, and NexLaw makes no
                      warranties or representations regarding its functionality,
                      accuracy, or suitability for any purpose.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>2. User Obligations</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">2.1</div>
                    <div class="col">
                      User agrees to use the Platform solely for the purpose of
                      beta testing and providing feedback to NexLaw.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">2.2</div>
                    <div class="col">
                      User shall not attempt to reverse engineer, modify, or
                      distribute the Platform or any of its components without
                      prior written consent from NexLaw.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">2.3</div>
                    <div class="col">
                      User shall keep all login credentials and access to the
                      Platform confidential and shall not share them with any
                      third party.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">2.4</div>
                    <div class="col">
                      User acknowledges that the Platform may collect and
                      process personal data as described in NexLaw's Privacy
                      Policy.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>3. Intellectual Property</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">3.1</div>
                    <div class="col">
                      User acknowledges that NexLaw retains all intellectual
                      property rights in the Platform, including but not limited
                      to software, algorithms, designs, and trademarks.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">3.2</div>
                    <div class="col">
                      User agrees not to use or exploit NexLaw's intellectual
                      property rights without prior written consent from NexLaw.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">3.3</div>
                    <div class="col">
                      User grants NexLaw the right to use User's name and logo
                      for promotional and marketing activities related to the
                      Platform, including but not limited to website content,
                      social media posts, and press releases. User automatically
                      allows NexLaw to use their logo on NexLaw's website and
                      marketing materials without the need for prior approval.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>4. Confidentiality</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">4.1</div>
                    <div class="col">
                      User acknowledges that any information, data, or materials
                      provided by NexLaw during the beta testing period are
                      confidential and proprietary to NexLaw.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">4.2</div>
                    <div class="col">
                      User agrees to keep all confidential information strictly
                      confidential and not to disclose it to any third party
                      without NexLaw's prior written consent.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>5. Limitation of Liability</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">5.1</div>
                    <div class="col">
                      User agrees that NexLaw shall not be liable for any
                      direct, indirect, incidental, consequential, or punitive
                      damages arising out of or in connection with the use of
                      the beta version of the Platform.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">5.2</div>
                    <div class="col">
                      User understands and acknowledges that the beta version of
                      the Platform may not be fully functional or error-free and
                      agrees to use it at their own risk.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>6. Termination</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">6.1</div>
                    <div class="col">
                      Either party may terminate this Agreement at any time for
                      any reason by providing written notice to the other party.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">6.2</div>
                    <div class="col">
                      Upon termination, User's access to the beta version of the
                      Platform shall cease, and all provisions regarding
                      confidentiality and intellectual property rights shall
                      survive.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>7. Governing Law and Jurisdiction</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">7.1</div>
                    <div class="col">
                      This Agreement shall be governed by and construed in
                      accordance with the laws of Australia.
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="custCol-1">7.2</div>
                    <div class="col">
                      Any disputes arising out of or in connection with this
                      Agreement shall be subject to the exclusive jurisdiction
                      of the courts of Australia.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="list-unstyled mb-4">
            <li>
              <strong>8. Entire Agreement</strong>
              <ul class="nested-list">
                <li>
                  <div class="row">
                    <div class="custCol-1">8.1</div>
                    <div class="col">
                      This Agreement constitutes the entire agreement between
                      NexLaw and the User regarding the beta testing of the
                      Platform and supersedes any prior agreements or
                      understandings, whether written or oral.
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>

          <p class="mt-3 mb-4">
            By ticking the "Accept" box on the Platform, the User acknowledges
            that they have read, understood, and agreed to be bound by the terms
            and conditions of this Beta User Agreement.
          </p>
        </div>
      </div>
    </b-modal>

    <h4 class="text-center font-weight-bold">Log In</h4>
    <!-- <div class="mb-3">
      <small>New User?</small>
      <small class="ms-1 text-primary" style="cursor: pointer"
        ><a @click="createAcc()" class="text-black fw-bolder td-underline"
          >Create an Account</a
        ></small
      >
    </div> -->

    <!-- <button class="btn btn-white mb-2" @click="loginWithGoogle">
      <img src="@/assets/authentication/icon-google.png" style="width: 25px" />
      <span class="ms-3 no-wrap">Login with Google</span>
    </button>

    <button class="btn btn-white mb-2" @click="loginWithMicrosoft">
      <img
        src="@/assets/authentication/icon-microsoft365.png"
        style="width: 25px"
      />
      <span class="ms-3 no-wrap">Login with Microsoft 365</span>
    </button>

    <div class="d-flex align-items-center my-2">
      <hr />
      <span class="mx-2">or</span>
      <hr />
    </div> -->

    <div class="login input-group mt-3 mb-1">
      <input
        type="email"
        autocorrect="off"
        autocomplete="off"
        name="email"
        v-validate="'required'"
        class="form-control"
        placeholder="Email"
        v-model="email"
        aria-label="Email"
        aria-describedby="Email"
      />
    </div>
    <span class="text-danger text-sm" v-show="errors.has('email')">
      {{ errors.first("email") }}
    </span>

    <div class="row">
      <div class="col-12">
        <div class="login input-group mt-3 mb-1 position-relative">
          <input
            :type="showPass ? 'text' : 'password'"
            autocorrect="off"
            autocomplete="off"
            name="password"
            v-validate="'required'"
            class="form-control"
            placeholder="Password"
            v-model="password"
            aria-label="Password"
            aria-describedby="Password"
            @input="removeSpaces"
            @keyup.enter="loginJWT"
          />

          <button
            style="cursor: pointer"
            @click="showPassword"
            class="btn-showPassword position-absolute float-right mt-1"
          >
            <span v-if="showPass" class="material-icons-outlined text-muted">
              visibility
            </span>
            <span v-else class="material-icons-outlined text-muted">
              visibility_off
            </span>
          </button>
        </div>
        <span class="text-danger text-sm" v-if="errors.has('password')">
          {{ errors.first("password") }}
        </span>

        <!-- <small class="mb-5 text-primary" style="cursor: pointer" @click="showPassword"> <small v-if="showPass">Show </small><small v-else>Hide </small>Password</small> -->
      </div>
    </div>

    <!-- <div class="w-100 custLeftMargin row">
      <div class="col float-right">
        <small>
          <a @click="forget" href="" class="text-muted">
          {{ "Forgot Password" }}
          </a>
        </small>
      </div>
    </div> -->

    <button
      @click="loginJWT"
      :disabled="disabled"
      class="btn btn-primary btn-signin mt-2"
    >
      {{ "Login" }}
    </button>
    
    <div class="row mt-4">
      <div class="col">
        <small>
          <a @click="forget" href="" class="text-muted float-right">
            {{ "Forgot Password" }}
          </a>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";
import jwt_decode from "jwt-decode";
import axios from "@/axios.js";

export default {
  data() {
    return {
      email: null,
      errorMessage: "",
      error: false,
      password: null,
      showPass: false,
      disabled: true,
      status: "not_accepted",
      isBetaUser: false,
    };
  },

  methods: {
    createAcc() {
      this.$router.push("/Creating-Account");
    },
    removeSpaces() {
      this.password = this.password.replace(/\s+/g, '');
    },
    showPassword() {
      this.showPass = !this.showPass;
    },

    loginWithGoogle() {
      if (location.href.includes("www.platform.nexlaw.ai")) {
        window.location.href =
          "https://api.platform.nexmind.ai/google/redirect/nexlaw";
      } else {
        window.location.href =
          "https://app.nexmind.nexodn.com/google/redirect/nexlaw";
      }
    },

    loginWithMicrosoft() {
      if (location.href.includes("www.platform.nexlaw.ai")) {
        window.location.href =
          "https://api.platform.nexmind.ai/microsoft/redirect/nexlaw";
      } else {
        window.location.href =
          "https://app.nexmind.nexodn.com/microsoft/redirect/nexlaw";
      }
    },

    validation() {
      // //
      if (this.email == null || this.email == "") {
        // //
        this.errorMessage = "Email required.";
        this.error = true;
        return true;
      } else if (!this.validEmail()) {
        // //
        this.errorMessage = "Invalid email format";
        this.error = true;
        return true;
      } else {
        this.error = false;
        return false;
      }
    },

    validEmail() {
      // //
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },

    loginJWT() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.disabled = true;
          const payload = {
            email: this.email,
            password: this.password,
          };

          // console.log("Status: ", this.status);
          // console.log("isBetaUser: ", this.isBetaUser);

          Auth.loginJWT(payload)
            .then((response) => {
              this.$toast.success(response.data.message);
              // localStorage.setItem("betaUserAgreement", this.status);

              // this.$bvToast.toast(response.data.message, {
              //   title: "Success",
              //   variant: "success",
              //   toaster: "b-toaster-bottom-left",
              //   solid: true,
              // });

              let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: true,
                onCancel: this.onCancel,
              });

              if (localStorage.userInfo == undefined) {
                var timer = setInterval(() => {
                  if (localStorage.userInfo != undefined) {
                    clearInterval(timer);
                    this.$router.push({ name: "Dashboard" });
                    loader.hide();
                  }
                }, 5000);
              } else {
                this.$router.push({ name: "Dashboard" });
                loader.hide();
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.disabled = false;
        }
      });
    },

    forget() {
      this.$router.push({ name: "enter_email" });
    },

    // openAgreement() {
    //   this.$bvModal.show("open-beta-user-agreement");
    // },
  },

  created() {
    let loader = this.$loading.show({
      // Optional parameters
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: true,
      onCancel: this.onCancel,
    });

    // if (localStorage.getItem("betaUserAgreement") === "accepted") {
    //   this.isBetaUser = true;
    //   this.status = "accepted";
    // }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    // console.log(urlParams);
    // console.log(token);

    if (token) {
      localStorage.setItem("accessToken", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      console.log(axios.defaults.headers.common["Authorization"]);
      axios
        .get("me")
        .then((res) => {
          loader.hide();
          localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.data.permissions)
          );
          const thisUser = res.data.data;
          let role = "editor";
          Object.assign(thisUser, { userRole: role });
          axios
            .get("locations")
            .then((res) => {
              var locations = [];
              res.data.data.forEach((element, index) => {
                // if (element.name == "www.google.com.my") {
                //   locations.push(element);
                // } else if (element.name == "www.google.com.au") {
                //   locations.push(element);
                // } else if (element.name == "www.google.com.sg") {
                //   locations.push(element);
                // }
                locations.push(element);
              });

              localStorage.setItem("locations", JSON.stringify(locations));
            })
            .catch((error) => {
              reject(error);
            });

          this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => {
          console.log(error);
          this.disabled = false;
          loader.hide();
        });
    } else if (localStorage.getItem("accessToken")) {
      loader.hide();

      if (
        localStorage.accessToken == undefined ||
        localStorage.accessToken == "undefined"
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        router.push("/login");
      } else {
        const token = localStorage.getItem("accessToken");
        const token_broken = jwt_decode(token);
        if (Date.now() < token_broken.exp * 1000) {
          loader.hide();
          axios
            .get("me")
            .then((res) => {
              loader.hide();
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              localStorage.setItem(
                "permissions",
                JSON.stringify(res.data.data.permissions)
              );
              const thisUser = res.data.data;
              let role = "editor";
              Object.assign(thisUser, { userRole: role });

              axios
                .get("locations")
                .then((res) => {
                  var locations = [];
                  res.data.data.forEach((element, index) => {
                    // if (element.name == "www.google.com.my") {
                    //   locations.push(element);
                    // } else if (element.name == "www.google.com.au") {
                    //   locations.push(element);
                    // } else if (element.name == "www.google.com.sg") {
                    //   locations.push(element);
                    // }
                    locations.push(element);
                  });

                  localStorage.setItem("locations", JSON.stringify(locations));
                })
                .catch((error) => {
                  reject(error);
                });

              this.$router.push({ name: "Dashboard" });
            })
            .catch((error) => {
              console.log(error);
              loader.hide();
            });
        } else {
          this.disabled = false;
        }
      }
    } else {
      loader.hide();
      this.disabled = false;
    }
  },
};
</script>

<style lang="css" scoped>
hr {
  color: #818181;
  width: 100%;
  margin: 0rem;
}

.login-form-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2.2rem;
  /* min-width: 350px; */
  /* min-height: 300px; */
  width: 420px;
  margin: 0 auto;
}

@media only screen and (max-width: 991px) {
  .login-form-card {
    min-width: auto;
    width: auto;
    max-width: 400px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 900px) {
  .login-form-card {
    min-width: auto;
    width: auto;
    margin: 0 auto;
  }
}

small {
  font-size: 14px;
}

small a {
  text-decoration: none;
}

.btn {
  width: 100%;
}

.btn-white {
  border-color: rgb(207, 207, 207);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-white:hover,
.btn-white:active {
  border-color: #e9e9e9;
  background-color: #e9e9e9;
}

.login.input-group {
  display: block;
}

.login.input-group .text-danger {
  margin-top: 0.5rem;
}

.login.input-group span {
  display: block;
}

.login.input-group input {
  border-radius: 6px !important;
  width: 100%;
}

.td-underline {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .login-form-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    /* min-width: 350px; */
    /* min-height: 300px; */
    margin: 0 auto;
  }
}

.btn-showPassword {
  z-index: 999;
  margin-left: auto;
  /* margin-right: 0.5rem; */
  right: 10px;
  translate: 0px -35px;
}

.nested-list {
  list-style: none;
  padding-left: 20px; /* Adjust this value as needed */
}

.custCol-1 {
  flex: 0 0 auto;
  width: 1.33333333%;
}

.juris-modal {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
