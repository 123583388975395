<template>
  <div class="container1">
    <div class="background-image">
      <div class="custom-right-margin custom-left-margin center-element">
        <img
          src="@/assets/nexmind/nexLaw_White_Logo.png"
          alt="Logo"
          class="logo-image custom-top-margin mb-4"
        />

        <h3 class="white-text mb-4 line-height-150">
          <strong
            >Few more taps away to experience the future of legal technology
            with AI</strong
          >
        </h3>

        <p class="white-text mb-4 line-height-200">
          NexLaw aims to harness the power of AI and automation to streamline
          routine legal work, cut costs, and improve access to justice for
          businesses and individuals.
        </p>
      </div>
    </div>

    <div class="content">
      <div class="custom-margin-top-white">
        <Login v-if="this.$route.fullPath.includes('login')" />
        <Login v-else-if="this.$route.fullPath.includes('signin')" />
        <Email v-else-if="this.$route.fullPath.includes('enter_email')" />
        <reset-password
          v-else-if="this.$route.fullPath.includes('Forgot-Password')"
        />
        <create-account
          v-else-if="this.$route.fullPath.includes('Creating-Account')"
        />
        <Result v-else-if="this.$route.fullPath.includes('result')" />
        <h6 class="text-muted font-14 line-height-150 mt-3 text-center">
          Licensed attorneys and other legal professionals, such as paralegals,
          law librarians, KM professionals, legal assistants, and practice
          managers have the opportunity to participate in demonstrations and
          trials.
        </h6>
      </div>
    </div>

    <!-- <div class="deco-container">
      <EventDecoration></EventDecoration>
    </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import Login from "./Login.vue";
import Email from "./EnterEmail.vue";
import ResetPassword from "./ResetPassword.vue";
// import CreateAccount from "./SignUp.vue";
import Result from "./ThankYou.vue";
import EventDecoration from "../../components/EventDecoration.vue";

export default {
  name: "Background",
  components: {
    Login,
    Email,
    ResetPassword,
    // CreateAccount,
    Result,
    EventDecoration,
  },
};
</script>

<style lang="css" scoped>
.container1 {
  display: flex;
  height: 100%;
  overflow: hidden; /* Hide overflow from the background image */
}

.background-image {
  flex: 1;
  background-image: url("../../assets/nexmind/newLoginBG.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.content {
  flex: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 40px;
  overflow-y: auto;
  overflow-x: hidden;
}

.center-element {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.logo-image {
  width: 300px;
  height: auto;
}

.white-text {
  color: #ffffff;
}

.custom-right-margin {
  margin-left: 4vw;
}

.custom-left-margin {
  margin-right: 4vw;
}

.custom-top-margin {
  margin-top: 9vw;
}

@media only screen and (max-width: 991px) {
  .container1 {
    flex-direction: column;
    height: auto;
  }

  .background-image {
    min-height: 40%;
  }

  .content {
    min-height: 60%;
  }

  .custom-top-margin {
    margin-top: 2vw;
  }
}

@media only screen and (max-width: 320px) {
  /* .custom-right-margin-white {
    margin-left: 3vw;
  }

  .custom-left-margin-white {
    margin-right: 1vw;
  } */

  .custom-margin-top-white {
    margin-top: 5vw;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  /* .custom-right-margin-white {
    margin-left: 3vw;
  }

  .custom-left-margin-white {
    margin-right: 1vw;
  } */

  .custom-margin-top-white {
    margin-top: 5vw;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  /* .custom-right-margin-white {
    margin-left: 3vw;
  }

  .custom-left-margin-white {
    margin-right: 1vw;
  } */

  .custom-margin-top-white {
    margin-top: 5vw;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  /* .custom-right-margin-white {
    margin-left: 22vw;
  }

  .custom-left-margin-white {
    margin-right: 20vw;
  } */

  .custom-margin-top-white {
    margin-top: 5vw;
  }
}

.deco-container {
  position: absolute;
  right: 0px;
  top: 8.5vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
