<template>
  <div class="form-card shadow-lg">
    <h4 class="font-weight-bold">Recover your password</h4>
    <div class="mb-3">
      <small> Please enter your email address and we'll send you instructions on how to reset your password.</small>
    </div>

    <div class="login input-group my-2">
      <input type="email" autocorrect="off" autocomplete="off" name="email" v-validate="'required'" class="form-control"
        placeholder="Email" v-model="email" aria-label="Email" aria-describedby="Email" @keyup.enter="forget" />
      <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>
    </div>

    <small class="mb-5"><a @click="$router.push({ name: 'SignIn' })" href="" class="text-muted float-right">Back to Login</a></small>

    <button @click="forget" class="btn btn-primary btn-signin mt-3">Continue</button>
  </div>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";

export default {
  data() {
    return {
      email: null,
      errorMessage: "",
    };
  },

  methods: {
    validEmail() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },

    forget() {
      if (!this.email) {
        this.errorMessage = "Email required.";
        return;
      } else if (!this.validEmail()) {
        this.errorMessage = "Invalid email format";
        return;
      }

      Auth.forgetPassword({
        email: this.email,
      })
        .then((res) => {
          this.$toast.success("Success! Please Check Your Email");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>


<style lang="css" scoped>
.form-card {
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 350px;
  padding: 2rem;
  width: 460px;
  margin: 0 auto;
}

small {
  font-size: 14px;
}

small a {
  text-decoration: none;
}

.btn {
  width: 100%;
}

.login.input-group {
  display: block;
}

.login.input-group .text-danger {
  margin-top: 0.5rem;
}

.login.input-group span {
  display: block;
}

.login.input-group input {
  border-radius: 6px !important;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .form-card {
    min-width: auto;
    width: auto;
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
  }
}

@media only screen and (max-width: 900px) {
  .form-card {
    min-width: auto;
    width: auto;
    margin: 0 auto;
    padding: 2rem;
  }
}
</style>
