<template>
  <div class="form-card shadow-lg">
    <h4 class="font-weight-bold">Thank You!</h4>

    <div class="mb-3">
      <small v-for="(item, index) in messages" :key="index">{{ item }}</small>
    </div>

    <button @click="Login" class="btn btn-primary btn-signin mt-3">
      Login
    </button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      email: null,
      messages: [],
    };
  },

  methods: {
    Login() {
      this.$router.push({ name: "SignIn" });
    },
  },

  created() {
    var vars = [],
      hash;

    var query_string = window.location.search;

    var hashes = query_string.slice(1).split("&");

    for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split("=");
      vars[hash[0]] = hash[1];
    }

    for (const [key, value] of Object.entries(vars)) {
      this.messages.push(decodeURI(value));
    }
  },
};
</script>

<style lang="css" scoped>
.form-card {
  background-color: #ffffff;
  border-radius: 10px;
  min-width: 350px;
  padding: 2rem;
  width: 460px;
  margin: 0 auto;
}

small {
  font-size: 14px;
}

small a {
  text-decoration: none;
}

.btn {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .form-card {
    min-width: auto;
    width: auto;
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
  }
}

@media only screen and (max-width: 900px) {
  .form-card {
    min-width: auto;
    width: auto;
    margin: 0 auto;
    padding: 2rem;
  }
}
</style>
